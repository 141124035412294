export const appHead = {"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Anyloader lets you download videos and photos you paid for from onlyfans posts, profiles and chats using chrome extension"},{"hid":"keywords","name":"keywords","content":"onlyfans downloader, download onlyfans video, download onlyfans photo"},{"name":"referrer","content":"no-referrer"},{"property":"og:type","content":"website"}],"style":[],"script":[{"tagPosition":"bodyClose","src":"https://www.googletagmanager.com/gtag/js?id=G-RPRXJ8QPY3","async":true},{"tagPosition":"bodyClose","innerHTML":"window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}  gtag('js', new Date()); gtag('config', 'G-RPRXJ8QPY3');"},{"tagPosition":"bodyClose","innerHTML":"(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3620959,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"},{"tagPosition":"bodyClose","innerHTML":"(function(d,t) {var BASE_URL=\"https://app.chatwoot.com\";var g=d.createElement(t),s=d.getElementsByTagName(t)[0];g.src=BASE_URL+\"/packs/js/sdk.js\";g.defer = true;g.async = true;s.parentNode.insertBefore(g,s);g.onload=function(){  window.chatwootSDK.run({websiteToken: 'uMX8ZkymnHfCz1ea1ZBCxtKx',baseUrl: BASE_URL  })}  })(document,\"script\")"}],"noscript":[],"title":"Free Onlyfans Downloader","htmlAttrs":{}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false