import { default as _91website_93_45downloaderpS03DIOnHpMeta } from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/[website]-downloader.vue?macro=true";
import { default as all_45downloaderss1BVjJfHcoMeta } from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/all-downloaders.vue?macro=true";
import { default as download_45onlyfans_45videosZddIwxyF0uMeta } from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/blog/download-onlyfans-videos.vue?macro=true";
import { default as indexFLEyPV69aAMeta } from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/blog/index.vue?macro=true";
import { default as ytsaver_45alternative2603BBvgU1Meta } from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/blog/ytsaver-alternative.vue?macro=true";
import { default as fansly_45downloader1SJL2rwh1qMeta } from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/fansly-downloader.vue?macro=true";
import { default as indexKhHwYt8nk5Meta } from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/index.vue?macro=true";
import { default as logseN2F2ClWy2Meta } from "/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/logs.vue?macro=true";
export default [
  {
    name: "website-downloader",
    path: "/:website()-downloader",
    component: () => import("/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/[website]-downloader.vue").then(m => m.default || m)
  },
  {
    name: "all-downloaders",
    path: "/all-downloaders",
    component: () => import("/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/all-downloaders.vue").then(m => m.default || m)
  },
  {
    name: "blog-download-onlyfans-videos",
    path: "/blog/download-onlyfans-videos",
    component: () => import("/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/blog/download-onlyfans-videos.vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-ytsaver-alternative",
    path: "/blog/ytsaver-alternative",
    component: () => import("/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/blog/ytsaver-alternative.vue").then(m => m.default || m)
  },
  {
    name: "fansly-downloader",
    path: "/fansly-downloader",
    component: () => import("/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/fansly-downloader.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "logs",
    path: "/logs",
    component: () => import("/Users/prakash/Projects/personal/onlyfans-downloader/frontend/pages/logs.vue").then(m => m.default || m)
  }
]